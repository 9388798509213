// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Breadcrumb navigation
//
// The path to the current page in the form of a list of links.
//
// Markup: breadcrumb.twig
//
// Style guide: navigation.breadcrumb

.breadcrumb,
%breadcrumb {
   @include typeface(latoLight);
   font-size: .9rem;
   margin-left: -12px;
   padding: 1.5rem 0 0;
   line-height: 1.2rem;

   a {
      text-decoration: none;
      color: color(blackgreen);
   }

   .breadcrumb__separator {
      padding: 5px;
      color: color(blackgreen);
   }

   @media print {
      display: none;
   }

   &__title {
      @extend %visually-hidden;
   }

   &__list {
      margin: 0;
      padding: 0;
   }

   &__item {
      display: inline;
      list-style-type: none;
      margin: 0;
      padding: 0;
   }

   @include respond-to(xs) {
      padding-top: 2rem;
      margin-left: -9px;
      font-size: 1rem;
      line-height: 1.1rem;
   }

   @include respond-to(m) {
      margin-left: unset;
      font-size: 1.1rem;
      line-height: 1.2rem;
   }
}

.page-search .breadcrumb {
   display: none;
}

.node-type-remote-video {
   article.node-remote-video {
      // padding: 0 4%;

      .remote-video-content {
         padding: 0 8%;
         margin-bottom: 1.3rem;
      }

      .remote-video-copy {
         display: grid;
         grid-gap: 1.3rem;
         margin-bottom: 2rem;

         .label {
            @include typeface(openSansLightItalic);
            font-size: 1.1rem;
            line-height: 1.1rem;
            letter-spacing: -.4px;
            color: color(blackgreenTwoThirds);
            font-weight: normal;
         }

         .content {
            @include typeface(latoLight);
            color: color(blackgreen);
            font-size: 1.2rem;
            line-height: 1.45rem;
            letter-spacing: -.8px;
            word-spacing: 1px;
            padding: .3rem 0 0 1rem;

            a {
               color: color(blackgreen);
            }
         }
      }
   }

   @include respond-to(s) {
      .article.node-remote-video {
         padding: 0 5%;

         .remote-video-content {
            padding: 0;
         }
      }
   }
}

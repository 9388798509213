.node-type-digital-collection-item {
   .node-digital-collection-item {
      display: grid;
      // display: flex;
      // flex-direction: column;
      // align-items: center;

      .digitem-metadata-content .field-label,
      .label {
         @include typeface(openSansLightItalic);
         font-size: 1.1rem;
         line-height: 1.1rem;
         letter-spacing: -.4px;
         color: color(blackgreenTwoThirds);
         font-weight: normal;
      }

      .digitem-metadata-content .field-item,
      .content {
         @include typeface(latoLight);
         letter-spacing: -.5px;
         line-height: 1.15rem;
         color: color(blackgreen);
         font-size: 1rem;
         padding: .2rem 0 0 .7rem;
         // word-break: break-all;

         a {
            color: color(blackgreen);
         }
      }

      .digitem-thumb {
         padding: 0 8%;
         margin-bottom: 1.8rem;
      }

      .digitem-metadata-wrapper,
      .digitem-metadata-content,
      .digitem-copy {
         display: grid;
         // sass-lint:disable no-misspelled-properties
         gap: 1.3rem;
         // sass-lint:enable no-misspelled-properties
      }

      .digitem-metadata {
         max-width: 100%;
         align-self: flex-start;
         margin-bottom: 2rem;

         .caret {
            @include typeface(fontAwesome);
            position: absolute;
            left: -10px;
            transform: rotate(0deg);
            transform-origin: left 50%;
            transition: transform 300ms;
         }

         .caret.open {
            transform: rotate(90deg);
            transform-origin: left 50%;
            transition: transform 300ms;
         }

         .digitem-metadata-label {
            position: relative;
            text-decoration: underline;
            margin: 0 0 1.2rem;

            span {
               cursor: pointer;
            }
         }
      }
   }

   @include respond-to(xs) {
      .node-digital-collection-item {

         .digitem-metadata-content .field-item,
         .content {
            font-size: 1.2rem;
            line-height: 1.45rem;
            letter-spacing: -.8px;
            word-spacing: 1px;
            padding: .3rem 0 0 1rem;
            // sass-lint:disable no-misspelled-properties
            gap: 1.5rem;
            // sass-lint:enable no-misspelled-properties
         }

         .digitem-metadata .digitem-metadata-label {
            margin: 0 0 1.7rem;
         }
      }
   }

   @include respond-to(s) {
      .node-digital-collection-item {

         .digitem-metadata-content .field-item,
         .content {
            padding-left: .9rem;
         }

         .digitem-content-wrapper {
            // display: flex;
            display: grid;
            grid-template-columns: 55fr 45fr;
            grid-gap: 1rem;
            align-items: flex-start;

            .digitem-thumb {
               // flex-basis: 46%;
               // margin: 0;
               padding: 0 4% 0 2%;
            }

            // .digitem-copy {
            //    flex-basis: 54%;
            // }
         }

         .digitem-metadata .digitem-metadata-label {
            margin: 0 0 1.3rem;
         }
      }
   }

   @include respond-to(m) {
      .node-digital-collection-item {
         .digitem-content-wrapper {
            .digitem-thumb {
               flex-basis: 50%;
            }

            .digitem-copy {
               flex-basis: 50%;
            }
         }
      }
   }

   @include respond-to(xl) {
      .node-digital-collection-item {

         .digitem-content-wrapper {
            .digitem-thumb {
               flex-basis: 55%;
            }

            .digitem-copy {
               flex-basis: 45%;
            }
         }

         // .digitem-metadata .digitem-metadata-content {
         //    grid-template-columns: 1fr 1fr 1fr;
         // }
      }
   }
}

// Dependencies.
// @import 'base/headings/headings';
@import 'components/clearfix/clearfix';
@import 'misc/font-min-max';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.hero-slideshow-wrapper {
   @include zen-new-row(both);

   background: radial-gradient(color(palegreen-zero), color(seaweed) 95%);

   .layout-center {
      padding: 0;
      max-width: unset;
   }

   .slick__slide {
      .media--image {
         width: 100%;
         // max-height: 24vw !important;
         height: unset !important;
      }

      img {
         height: 50vw;
         max-width: unset;
         width: unset;
         position: relative;
         left: 50%;
         transform: translateX(-50%);
      }

      @include zen-respond-to(xs) {
         img {
            height: auto;
            width: 100%;
            max-width: 100%;
            position: static;
            left: unset;
            transform: unset;
         }
      }

      .slide__caption {
         background-color: rgba(0, 0, 0, .6);
         // padding: 0 10%;
         padding: 0;
         min-height: unset;
         position: absolute;
         bottom: 0;

         .slide__title {
            @include typeface(heroSlideshowTitle);
            width: 41%;
            margin: 0;
            clear: none;
            display: inline-block;
            line-height: 1rem;
            text-align: right;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 0 2.3% .25rem 2%;

            a {
               color: #ecdfcb;
               text-decoration: none;
               font-weight: normal;
               font-size: calc(1.06vw + 10.6px);
               line-height: calc(1.06vw + 13.6px);
            }
         }

         .slide__description {
            float: right;
            width: 59%;
            // display: inline-block;
            padding: 0 2% 0 2.3%;

            .separator {
               width: 1px;
               background-color: #ecdfcb;
               height: 80%;
               position: absolute;
               top: 50%;
               transform: translateY(-50%);
               right: 59%;
            }

            .slide-copy--short {
               display: initial;
            }

            .slide-copy--medium {
               display: none;
            }

            .slide-copy--long {
               display: none;
            }

            @include zen-respond-to(l) {
               .slide-copy--medium {
                  display: initial;
               }

               .slide-copy--short,
               .slide-copy--long {
                  display: none;
               }
            }

            @include zen-respond-to(xxxl) {
               .slide-copy--long {
                  display: initial;
               }

               .slide-copy--short,
               .slide-copy--medium {
                  display: none;
               }
            }

            p {
               @include typeface(heroSlideshowCaption);
               // We want the font size to be proportional to the viewport width
               //  - determine the font sizes we want at two different viewport widths
               //  - set an equation for each viewport width & font size above:
               //       (x * viewport width) + y = font size
               //  - solve one equation for y and then substitute that y-value
               //    into the other equation, then solve that equation for x
               //  - substitute the derived x-value into either initial
               //    equation and solve for y
               //  - multiply x-value by 100 for the vw coefficient
               //  - y-value is already in pixels
               font-size: calc(12px + .52vw);
               // font-size: calc(9.09px + .98vw);
               color: #ecdfcb;
               margin: 0;
               padding: .7em 0;
               line-height: 1.2em;
            }

            // p:last-of-type {
            //    padding-bottom: 1em;
            // }
         }
      }

      @include zen-respond-to(m) {
         .slide__caption {
            .slide__title {
               padding-left: 7%;
            }

            .slide__description {
               padding-right: 7%;
            }
         }
      }
   }

   @include zen-respond-to(xl) {
      .slick__slide {
         .slide__caption {
            .slide__title {
               width: calc(-3.14vw + 441.36px);
               padding-right: 23px;
            }

            .slide__description {
               width: calc(103.14vw - 441.36px);
               padding-left: 23px;

               .separator {
                  left: calc(-3.14vw + 441.36px);
               }
            }
         }
      }
   }

   @include zen-respond-to(xxxl) {
      .slick__slide {
         .slide__caption {
            .slide__title {
               width: 33%;
            }

            .slide__description {
               width: 67%;

               .separator {
                  left: unset;
                  right: 67%;
               }
            }
         }
      }
   }

   .slick--view--hero-slideshow {
      overflow: hidden;

      .slick__arrow {
         line-height: 40px;
         list-style: none;

         .slick-arrow {
            width: 4vw;
            height: 4vw;
            min-width: 8.533333333vw;
            min-height: 8.533333333vw;
            position: absolute;
            top: 50%;
            overflow: hidden;
            cursor: pointer;
            background-color: unset;
            opacity: 0;
            border: 0;
            padding: 0;
            transition: all .3s ease-in-out;    // sass-lint:disable-line no-transition-all
            margin: 0;
            transform: translateY(-50%);
         }

         .slick-arrow::before {
            @include responsive-font(6vw, 32px, 200px);
            @include typeface(fontAwesome);
            color: #999;
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
         }

         .slick-arrow.slick-prev {
            left: -50px;
         }

         .slick-arrow.slick-prev::before {
            content: '\f104';
            left: 0;
         }

         .slick-arrow.slick-next {
            right: -50px;
            // width: 0;
            // display: none !important;
         }

         .slick-arrow.slick-next::before {
            content: '\f105';
            right: 0;
         }
      }
   }

   .slick--view--hero-slideshow:hover .slick__arrow {

      .slick-arrow.slick-prev {
         opacity: .7;
         left: 15px;
      }

      .slick-arrow.slick-prev:hover {
         opacity: 1;
      }

      .slick-arrow.slick-next {
         opacity: .7;
         right: 15px;
         // width: 4vw;
         // display: block;
      }

      .slick-arrow.slick-next:hover {
         opacity: 1;
      }

      .slick-arrow:hover::before {
         color: white;
         transition: color .2s ease-in-out;
      }
   }
}

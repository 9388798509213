.layout-header,
%layout-header {

   @include zen-grid-container($context: flow);
   @include zen-new-row(both);

   height: 100%;
   min-height: 13vw;
   position: relative;
   overflow: hidden;

   &__site-slogan {
      display: none;
      padding: 0 10%;
   }

   &__seal {
      // @include zen-grid-item-base();
      // @include zen-grid-item(1, 1);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
   }

   &__seal img {
      padding: 0 25%;
      display: block;
      margin: 0 auto;
   }

   @include zen-respond-to(xxs) {
      height: 100%;
      // position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      width: 100%;

      &__title {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         width: 100%;
      }
   }

   @include zen-respond-to(m) {
      &__title {
         @include zen-grid-item(1, 1);
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         text-align: center;
         padding-bottom: 0;
      }

      &__site-slogan {
         @include zen-grid-item(1, 2);
         display: block;
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         padding: 0 6%;
      }

      &__seal {
         @include zen-grid-item(2, 1);
      }

      // @include zen-grid-background($grid-color: #ffdede06);  // sass-lint:disable-line no-invalid-hex
   }

   @include zen-respond-to(xl) {
      &__title {
         // @include zen-grid-item(4, 1);
         // text-align: right;
         // padding: 0 0 8px;
         @include zen-grid-item(1, 1);
      }

      &__site-slogan {
         // @include zen-grid-item(4, 9);
         // padding: 0 6% 8px 0;
         @include zen-grid-item(1, 2);
      }

      &__seal {
         // @include zen-grid-item(6, 4);
         @include zen-grid-item(2, 1);
      }

      &__seal img {
         padding: 0;
      }

      // @include zen-grid-background($grid-color: #ffdede06);  // sass-lint:disable-line no-invalid-hex
   }
}

.page-search {
   .views-exposed-widgets::before,
   .views-exposed-widgets::after {
      display: none;
   }

   .views-exposed-widgets {
      // sass-lint:disable class-name-format no-misspelled-properties
      margin: 0;
      // display: grid;
      // grid-template-rows: 1fr 1fr;
      // grid-template-columns: 1fr auto;
      // gap: 1.1rem;
      // grid-column: 1/3;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      .views-widget-filter-search_api_views_fulltext {
         padding: 0;
         width: 100%;
         order: 1;

         .form-item-search-fulltext {
            input {
               @include typeface(openSansLight);
               font-size: 1.2rem;
               line-height: 2rem;
               color: color(blackgreen);
               width: 100%;
               padding-left: 10px;
            }
         }
      }
      // sass-lint:enable class-name-format no-misspelled-properties

      .views-submit-button {
         order: 3;
      }

      .views-reset-button {
         order: 2;
      }

      .views-exposed-widget.views-submit-button,
      .views-exposed-widget.views-reset-button {
         padding: 1.1rem 0 0 1.1rem;

         input {
            margin: 0 !important;
            font-family: OpenSans;
            font-size: 16.8px;
            letter-spacing: -.4px;
            padding: .3rem .7rem;
            color: color(blackgreen);
            background-image: linear-gradient(180deg, #e7e7e7, #fff);
         }
      }
   }

   .block-facetapi {
      h2 {
         font-family: LatoBoldItalic;
         font-size: 1.1rem;
         color: color(seaweedDarker);
         cursor: pointer;
         margin: 1.2rem 0 0;

         .caret {
            @include typeface(fontAwesome);
            padding-right: .5rem;
            display: inline-block;
            transform: rotate(0deg);
            transform-origin: left 50%;
            transition: transform 300ms;
         }

         .caret.open {
            transform: rotate(90deg);
            transform-origin: left 50%;
            transition: transform 300ms;
         }

         .text {
            text-decoration: underline;
         }
      }

      .item-list {
         display: none;

         ul {
            // sass-lint:disable no-misspelled-properties
            list-style: none;
            padding: .9rem 0 0;
            margin: 0 0 0 .9rem;
            display: grid;
            gap: 1.4rem;

            li {
               font-family: LatoLight;
               font-size: 1.05rem;
               letter-spacing: -.35px;
               line-height: 1.15rem;
               display: flex;
               align-items: center;

               .facetapi-checkbox {
                  margin-right: .2rem;
               }

               a {
                  text-decoration: none;
                  color: color(seaweedDarker);
               }
            }
            // sass-lint:enable no-misspelled-properties
         }
      }

      @include respond-to(xs) {
         h2 {
            font-size: 1.2rem;
         }

         .item-list ul li {
            font-size: 1.15rem;
         }
      }
   }

   .view-search {
      margin-top: 3rem !important;

      .view-empty .no-search-results {
         @include typeface(openSans);
         font-size: 1.2rem;
         line-height: 2rem;
         color: #062f32;
         // width: 100%;
         // padding-left: 10px;
      }

      .views-row {
         margin-bottom: 4rem;
      }

      .node-pnc-person,
      .node-pnc-course,
      .node-pnc-institution,
      .node-pnc-coursegroup,
      .node-remote-video,
      article.node-digital-collection-item {
         // sass-lint:disable no-misspelled-properties
         display: grid;
         grid-template-columns: 42fr 58fr;
         gap: 1.2rem;

         > header {
            grid-column: 1/3;
         }

         .results-item-thumb {
            line-height: 0;

            img {
               box-shadow: 0 0 4px 1px rgba(103, 103, 103, .3);
            }

            .pnc-canvas {
               width: 36vw;
               height: 48vw;
               background-color: #c7d0d0;
               box-shadow: 0 0 4px 1px rgba(103, 103, 103, .3);
               border: hsla(180, 9%, 75%, 1) solid 1px;
               display: grid;
               grid-template-rows: 1fr 3fr 1fr;

               .icon-wrapper {
                  @include typeface(fontAwesome);
                  font-size: 25vw;
                  line-height: 7vw;
                  grid-row: 2/3;
                  justify-self: center;
                  align-self: center;

                  .icon {
                  background-color: #5f6c71;
                  color: transparent;
                  text-shadow: 0 1px 2px rgba(255, 255, 255, .57);
                  -webkit-background-clip: text;
                     -moz-background-clip: text;
                          background-clip: text;
                  }
               }

               .canvas-copy {
                  @include typeface(latoRegular);
                  font-size: 4.2vw;
                  line-height: 1.1rem;
                  letter-spacing: -.2px;
                  color: color(blackgreenTwoThirds);
                  justify-self: center;
                  align-self: center;
               }

               .canvas-copy.lower {
                  grid-row: 3/4;
               }
            }
         }

         .results-item-copy {
            @include typeface(openSansLight);
            padding-left: 6%;
            flex-basis: 58%;
            font-size: .85rem;
            line-height: 1.1rem;
            letter-spacing: -.2px;
            color: color(blackgreen);

            .pnc-field-name,
            .field-name-field-video-title,
            .field-name-dc-title {
               @include typeface(latoBoldItalic);
               font-size: 1.1rem;
               line-height: 1.2rem;
               letter-spacing: .2px;
               margin-bottom: .7rem;

               a {
                  color: color(blackgreen);
                  text-decoration: none;
               }

               a:hover {
                  text-decoration: underline;
               }
            }

            .field-label-above {
               margin-bottom: .9rem;
               font-family: OpenSansLight, Verdana, sans-serif;
               font-size: .85rem;
               line-height: 1.1rem;
               letter-spacing: -.2px;
               color: color(blackgreen);

               .field-label {
                  color: color(blackgreenTwoThirds);
               }

               .field-items {
                  font-style: italic;

                  a {
                     color: color(blackgreen);
                  }
               }
            }
         }
         // sass-lint:enable no-misspelled-properties
      }

      .node-pnc-person::before,
      .node-pnc-person::after,
      .node-pnc-course::before,
      .node-pnc-course::after,
      .node-pnc-institution::before,
      .node-pnc-institution::after,
      .node-pnc-coursegroup::before,
      .node-pnc-coursegroup::after,
      .node-remote-video::before,
      .node-remote-video::after,
      article.node-digital-collection-item::before,
      article.node-digital-collection-item::after {
         display: none;
      }

      @include respond-to(xs) {        // 555px
         .view-content {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            padding: 0;
         }

         .views-row {
            // flex-direction: column;
            flex-basis: 50%;
            margin-bottom: 5rem;
            // align-items: center;

            article.node-pnc-person,
            article.node-pnc-course,
            article.node-pnc-institution,
            article.node-pnc-coursegroup,
            article.node-remote-video,
            article.node-digital-collection-item {
               display: flex;
               flex-direction: column;
               flex-basis: 50%;
               align-items: center;

               .results-item-thumb {
                  flex-basis: unset;
                  margin: 0 11%;
                  position: relative;
               }

               .results-item-copy {
                  flex-basis: unset;
                  margin: .5rem 11% 0;
                  padding: 0 1%;
                  align-self: flex-start;

                  .pnc-field-name,
                  .field-name-field-video-title,
                  .field-name-dc-title {
                     font-size: 1.25rem;
                     line-height: 1.4rem;
                     margin-bottom: .8rem;
                     text-align: left;
                  }

                  .field-label-above {
                     font-size: .9rem;
                     line-height: 1.2rem;
                     letter-spacing: -.4px;
                  }
               }

               .canvas-copy.upper {
                  position: absolute;
                  top: 7%;
                  left: 50%;
                  transform: translate(-50%);
                  width: max-content;
               }
            }
         }
      }

      @include respond-to(m) {
         .views-row {
            flex-basis: 33.3333333%;
         }

         .node-pnc-person,
         .node-pnc-course,
         .node-pnc-institution,
         .node-pnc-coursegroup {
            .results-item-thumb .pnc-canvas {
               width: 21.3vw;
               height: 28.4vw;

               .icon {
                  font-size: 16vw;
                  line-height: 16vw;
               }

               .canvas-copy {
                  font-size: 2.2vw;
               }
            }
         }
      }

      @include respond-to(l) {
         .views-row {
            article.node-digital-collection-item {
               .results-item-copy {
                  .field-name-field-video-title,
                  .field-name-dc-title {
                     font-size: 1.35rem;
                     line-height: 1.5rem;
                  }

                  .field-name-dc-date-createdstandard,
                  .field-name-field-dspace-repo-collection,
                  .field-name-dc-type {
                     font-size: 1rem;
                     line-height: 1.3rem;
                  }
               }
            }
         }
      }

      @include respond-to(xxxl) {      // 1222px
         .node-pnc-person,
         .node-pnc-course,
         .node-pnc-institution,
         .node-pnc-coursegroup {
            .results-item-thumb .pnc-canvas {
               width: 260px;
               height: 347px;

               .icon {
                  font-size: 195.5px;
                  line-height: 195.5px;
               }

               .canvas-copy {
                  font-size: 26.89px;
               }
            }
         }
      }
   }
}

@include respond-to(m) {
   .page-search {
      #block-views-exp-search-page,
      .block-facetapi,
      .view-id-search {
         padding: 0 6%;
         margin: 0 auto;
      }
   }
}

@include respond-to(l) {
   .page-search .views-exposed-widgets {
      flex-wrap: nowrap;
      justify-content: flex-start;

      // sass-lint:disable class-name-format no-misspelled-properties
      .views-widget-filter-search_api_views_fulltext {
         max-width: 550px;
      }
      // sass-lint:enable class-name-format no-misspelled-properties

      .views-exposed-widget {
         padding: 0 1rem 0 0 !important;
      }
   }
}

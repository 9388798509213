.layout-genre,
%layout-genre {

   @include zen-grid-container();

   // display: flex;
   // flex-direction: column;
   // justify-content: space-between;

   // min-height: 13vw;
   // padding: 2% 4%;
   // overflow: hidden;
   // position: relative;

   // &__title {
   //    padding-bottom: 2%;
   // }

   // @include zen-apply-gutter-padding();




   @include zen-respond-to(sl-zero) {
      // @include zen-apply-gutter-padding();

      // h1 {
      //    @include zen-grid-item(6, 1);
      // }

      @include zen-new-row(both);

      .genre__row {

         // .genre__image {
         //    // @include zen-grid-item(3, 1);

         // }

         .genre__exposition {
            height: 24vh;
            width: 35.5vh;
            background-color: #d8d1c7;
         }
      }

      .genre__copy {
         .first {
            @include zen-grid-item(4, 3);
         }

         .second {
            @include zen-grid-item(2, 1);
         }
      }



      // @include zen-grid-background();
   }

}

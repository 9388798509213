// Dependencies.
// @import 'base/headings/headings';
// @import 'components/clearfix/clearfix';
// @import 'misc/font-min-max';

// Frontpage spotlights based on genre of content
//
// Markup: spotlights.twig
//

.genre,
%genre {

   @extend %clearfix;

   $hover-on-duration: 1.2s;
   $hover-off-duration: .6s;

   background-color: color(hintofbluegreen);

   &__type {
      display: flex;
   }

   // &__type.correspondence-documents {
   //    .genre__exposition a .title-wrapper .underline-box {
   //       bottom: .25rem;
   //    }
   // }

   &__image {
      line-height: 0;
      overflow: hidden;
      height: 42.67vw;
      position: relative;

      .sheer {
         width: 100%;
         height: 100%;
         opacity: .85;
         transition: opacity $hover-off-duration;
         position: absolute;
         background-color: white;
      }

      img {
         height: calc(21.35vw + 91.77px);
         max-width: unset;
         min-height: 42.67vw;
      }
   }

   &__exposition {
      width: 62.93vw;
      height: 42.67vw;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      box-shadow: none;
      z-index: 0;
      transition: box-shadow $hover-off-duration,
                  z-index $hover-off-duration;

      a {
         text-decoration: none;
         height: 100%;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         position: relative;

         .title-wrapper {
            position: relative;
            overflow: hidden;

            h1 {
               @include typeface(genreTitle);
               --container-width: calc(31.87vw + 136.56px);
               font-size: calc(.0898 * var(--container-width) - 5.15px);
               letter-spacing: calc(.0132 * var(--container-width) + 4.6px);
               line-height: calc(1em + 6px);
               margin: 0 calc(-1 * (.0132 * var(--container-width) + 4.6px)) 0 0;
               padding-left: 0;
               color: color(blackgreen);
               text-transform: uppercase;
               opacity: .7;
               transition: opacity $hover-off-duration;
            }

            .underline-box {
               height: 2px;
               background-color: color(blackgreen);
               position: absolute;
               width: 100%;
               bottom: .05rem;
               opacity: 0;
               transition: opacity $hover-off-duration;
            }
         }
      }
   }

   .correspondence-documents &__exposition .title-wrapper h1 {
      --container-width: calc(31.87vw + 136.56px);
      font-size: calc(.0743 * var(--container-width) - 3.52px);
      letter-spacing: calc(.0225 * var(--container-width) - 1.01px);
      margin: 0 calc(-1 * (.0225 * var(--container-width) - 1.01px)) 0 0;
      padding-left: 0;
   }

   .genre__citation {
      position: absolute;
      bottom: 9%;
      padding-left: 2.8%;
      color: #062f32;
      display: none;

      h4 {
         font-family: LatoLight;
         margin: 0;
         padding: 0;
         font-size: 13.5px;
         line-height: 1.4em;
      }

      h5 {
         font-family: LatoLight;
         margin: 0;
         padding: 0;
         font-size: 12.5px;
         font-style: italic;
         line-height: 1.4em;
      }

   }

   .photographs &__image {
      img {
         object-position: -46px;
      }
   }

   .publications &__image {
      img {
         object-position: -53px;
      }
   }

   &__type:hover {
      .sheer {
         opacity: 0;
         transition: opacity $hover-on-duration;
      }
   }

   &__type:hover &__exposition {
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, .34);
      z-index: 10;
      transition: box-shadow $hover-on-duration,
                  z-index $hover-on-duration;

      h1 {
         opacity: 1;
         transition: opacity $hover-on-duration;
      }
   }

   &__type &__exposition:hover {
      .underline-box {
         opacity: 1;
         transition: opacity $hover-on-duration;
      }
   }


   .photographs &__exposition {
      background-color: #dbd1c7;
   }

   .exhibits &__exposition {
      background-color: #ced4e1;
   }

   .video-audio &__exposition {
      background-color: #d8d8d8;
   }

   .publications &__exposition {
      background-color: #c7d0d0;
   }

   .correspondence-documents &__exposition {
      background-color: #f3f6f7;

      h1 {
         font-size: 3.9vw;
         line-height: 1.3em;
         // text-align: center;
         letter-spacing: 1.19vw;
      }
   }

   .exhibits,
   .publications {
      flex-direction: row-reverse;
   }


   @include zen-respond-to(xxs) {
      &__exposition {
         width: calc(31.87vw + 136.56px);
         height: calc(21.35vw + 91.77px);

         a .title-wrapper h1 {
            --container-width: calc(31.87vw + 136.56px);
            font-size: calc(.0898 * var(--container-width) - 5.15px);
            letter-spacing: calc(.0132 * var(--container-width) + 4.71px);
            line-height: calc(1em + 6px);
            margin: 0 calc(-1 * (.0132 * var(--container-width) + 4.71px)) 0 0;
            padding-left: 0;
         }
      }

      .correspondence-documents &__exposition .title-wrapper h1 {
         --container-width: calc(31.87vw + 136.56px);
         font-size: calc(.0743 * var(--container-width) - 3.52px);
         letter-spacing: calc(.0225 * var(--container-width) - 1.01px);
         margin: 0 calc(-1 * (.0225 * var(--container-width) - 1.01px)) 0 0;
         padding-left: 0;
      }

      &__image {
         height: calc(21.35vw + 91.77px);
      }

      .photographs &__image {
         img {
            object-position: calc(28.75vw - 173.65px);
         }
      }

      .publications &__image {
         img {
            object-position: calc(19.7vw - 136.45px);
         }
      }
   }

@include zen-respond-to(xs) {
   &__exposition {
      a .title-wrapper .underline-box {
         height: 2px;
      }
   }
}

@include zen-respond-to(xl) {
   &__exposition {
      a .title-wrapper .underline-box {
         height: 3px;
         bottom: .12rem;
      }
   }
}

   @media (min-width: 510px) {
      .publications &__image img {
         object-position: unset;
      }
   }

   @media (min-width: 540px) {
      .exhibits &__image img {
         width: 100%;
         height: unset;
      }
   }

   @media (min-width: 604px) {
      .photographs &__image img {
         object-position: unset;
      }
   }

   @media (min-width: 631px) {
      .publications &__image img {
         width: 100%;
         height: unset;
      }
   }

   @media (min-width: 656px) {
      .video-audio &__image img {
         width: 100%;
         height: unset;
      }
   }

   @media (min-width: 816px) {
      .correspondence-documents &__image img {
         width: 100%;
         height: unset;
      }
   }

   @media (min-width: 912px) {
      .photographs &__image img {
         width: 100%;
         height: unset;
      }
   }
}

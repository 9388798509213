// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Navbar
//
// A simple method to get navigation links to appear in one line.
//
// Markup: navbar.twig
//
// Style guide: navigation.navbar

// .mini-navbar {
//    position: relative;
// }

.navbar-wrapper {
   @include zen-new-row(both);

   // background-color: color(navbar-bg);
   background-color: rgba(4, 6, 6, .29);
   position: absolute;
   width: 100%;
   bottom: 0;
   height: 34px;

   .sf-main-menu {
      display: flex;
      justify-content: space-evenly;
      max-width: 1070px;
      margin-left: auto;
      margin-right: auto;

      .active-trail > span,
      .active-trail > a {
         background-color: color(papyrusSandish) !important;
         color: color(seaweed) !important;
         font-weight: bold;
      }

      .active-trail:not(.sf-depth-1) {
         background-color: color(papyrusSandish) !important;
      }

      li {
         text-align: center;

         .nolink {
            cursor: pointer;
         }

         .nolink,
         a {
            font-family: OpenSansLight;
            color: color(papyrusSand);
            text-decoration: none;
            padding: .7rem 30px;
         }

         li:not(.sf-depth-1) {
            background-color: color(seaweed);
            overflow: hidden;
         }

         // .nolink:hover,
         a:hover {
            text-decoration: underline;
         }

      }

      // sass-lint:disable class-name-format
      li:hover > ul,
      li.sfHover > ul {
         top: 2.5rem;
      }

      li:hover > ul a,
      li.sfHover > ul a {
         width: fit-content;
         margin-left: auto;
         margin-right: auto;
         background-color: color(seaweed);
      }

      li.sfHover li:not(.sf-depth-1) {
         background-color: color(seaweed);
      }
      // sass-lint:enable class-name-format
   }

   .sf-accordion li {
      background-color: #185152;
   }

   .sf-accordion-toggle.sf-style-none {
      text-align: right;

      a {
         color: color(papyrusSand);
         text-decoration: none;
         font-size: 2.2rem;
         line-height: 1.9rem;
      }
   }

   @include respond-to(xxs) {
      height: 36px;

      .sf-accordion-toggle.sf-style-none {
         a {
            line-height: 2rem;
         }
      }
   }

   @include respond-to(xs) {     //      555px
      height: 40px;

      .nolink,
      a {
         font-size: 1rem;
         line-height: 1.15rem;
      }

      .sf-main-menu {
         // sass-lint:disable class-name-format
         li:hover > ul,
         li.sfHover > ul {
            top: 40px;
         }
         // sass-lint:enable class-name-format
      }

      .sf-accordion-toggle.sf-style-none {
         a {
            font-size: 2.5rem;
            line-height: 2.3rem;
         }
      }
   }

   @include respond-to(l) {    //    888px

      .sf-main-menu {
         .nolink,
         a {
            font-size: 1.1rem;
            line-height: 1.15rem;
         }
      }
   }
}


//
// Drupal selectors.
//

.main-navigation {
   // Hide the block titles put into the main-navigation area of the page.tpl.php.
   .block-menu .block__title,
   .block-menu-block .block__title {
      @extend %visually-hidden;
   }

   // // Main menu and secondary menu links and menu block links.
   // .links,
   // .menu {
   //    @extend %navbar;
   // }
}

// sass-lint:disable id-name-format

.section-people-and-courses,
%section-people-and-courses {
   #views-exposed-form-people-and-courses-search-page {
      .views-widget-filter-title {
         @include typeface(latoBoldItalic);
         color: color(blackgreen);
         font-size: 1.1rem;
         line-height: 1.2rem;
         letter-spacing: .2px;

         label {
            margin-bottom: .4rem;
         }
      }

      .views-submit-button {
         display: none;
      }
   }

   .view-people-and-courses {
      a {
         color: color(blackgreen);
      }

      .nav-tabs {
         @include typeface(latoLight);
         font-size: .85rem;
         letter-spacing: -.2px;
         margin-top: 0;
         display: flex;
         flex-direction: row;

         .nav-item a {
            padding: .2rem .7rem;
            text-decoration: none;
         }
      }

      .nav-tabs#personTab {
         justify-content: space-around;
      }

      .nav-tabs#courseTab {
         justify-content: space-around;
      }
   }

   .subtitle {
      @include typeface(latoRegular);
      font-size: 1.5rem;
      line-height: 1.8rem;
      letter-spacing: -.6px;
      margin-bottom: 1rem;
      padding: 0 5%;
      color: color(blackgreenTwoThirds);
   }

   .upper-content,
   .nav-tabs#courseTab {
      padding: 0 .6rem;
   }

   .upper-content .field-wrapper,
   .coursegroup-wrapper {
      margin-bottom: 1rem;
   }

   .upper-content,
   .coursegroup-wrapper {
      .label {
         @include typeface(openSansLightItalic);
         font-size: 1.1rem;
         line-height: 1.1rem;
         letter-spacing: -.4px;
         color: rgba(6, 47, 50, .66);
         font-weight: normal;
      }

      .content {
         @include typeface(latoLight);
         letter-spacing: -.5px;
         line-height: 1.15rem;
         color: #062f32;
         font-size: 1rem;
         padding: .2rem 0 0 .7rem;
         // word-break: break-all;
      }
   }

   .tab-content {
      margin-bottom: 2rem;

      ul {
         list-style: none;
         padding-left: .6rem;
      }
   }

   .node-pnc-coursegroup .courses.triplet {
      margin-top: 0;
   }

   .affiliates-name {
      @include typeface(latoItalic);
      font-size: 1.35rem;
      line-height: 1.25rem;
      letter-spacing: -.2px;
      margin-bottom: .2rem;
   }

   .affiliates-wrapper {
      margin-bottom: 2rem;

      ul.position {
         list-style: none;
         margin: 0;
         padding: .7rem;

         .affiliates-item {
            margin-bottom: 0;
         }
      }

      ul.position.duplet.odd {
         background-image: radial-gradient(circle 46vw, #e0e0e0, #edeeec);
      }
   }

   .pnc-field-item {
      margin-bottom: 1.2rem;

      .first {
         @include typeface(openSansLight);
         font-size: .95rem;
         line-height: 1.1rem;
         letter-spacing: -.2px;
         color: rgba(6, 47, 50, .78);
      }

      .second {
         @include typeface(latoItalic);
         font-size: 1.35rem;
         font-weight: 100;
         line-height: 1.45rem;
         letter-spacing: -.1px;
         color: color(blackgreen);

         .unknown {
            color: color(blackgreenTwoThirds);
            font-weight: 100;
            letter-spacing: -.3px;
         }
      }

      .third {
         @include typeface(openSansLight);
         font-size: .95rem;
         line-height: 1.25rem;
         color: color(blackgreenTwoThirds);

         a {
            color: color(blackgreenTwoThirds);
         }
      }

      .yearpos-wrapper {
         .year {
            @include typeface(openSansLight);
            font-size: 1rem;
            line-height: 1rem;
            letter-spacing: -1.1px;
            color: rgba(6, 47, 50, .78);
         }

         .position {
            @include typeface(latoLight);
            font-weight: 900;
            font-size: 1.25rem;
            line-height: 1.45rem;
            letter-spacing: -.4px;
            color: color(blackgreen);
         }
      }
   }

@include respond-to(xxs) {    // 444px
   #views-exposed-form-people-and-courses-search-page {
      .views-widget-filter-title {
         font-size: 1.2rem;
         line-height: 1.4rem;
      }
   }

   .view-people-and-courses {
      .nav-tabs {
         font-size: 1.1rem;

         .nav-item a {
            padding: .3rem .7rem;
         }
      }
   }

   .affiliates {
      padding: 0 1.3rem;
   }

   .upper-content,
   .nav-tabs#courseTab {
      padding: 0 1rem;
   }

   .tab-content {
      ul {
         padding-left: 1rem;
      }
   }

   .pnc-field-item {
      .first {
         font-size: 1.04rem;
         line-height: 1.1rem;
         letter-spacing: -.4px;
      }

      .second {
         font-size: 1.4rem;
         line-height: 1.7rem;
         // letter-spacing: .4px;
      }

      .third {
         font-size: 1rem;
         line-height: 1.4rem;
         letter-spacing: -.4px;
      }
   }
}


@include respond-to(xs) {    // 555p
   #views-exposed-form-people-and-courses-search-page {
      .views-widget-filter-title {
         font-size: 1.25rem;
         line-height: 1.4rem;
      }
   }

   .view-people-and-courses {
      .nav-tabs#courseTab {
         justify-content: space-around;
         padding: 0 9%;
         margin: 0;
      }
   }

   .affiliates {
      padding: 0 2.2rem;
   }

   .upper-content,
   .nav-tabs#courseTab {
      padding: 0 1rem;
   }

   .upper-content,
   .coursegroup-wrapper {
      .content {
         font-size: 1.2rem;
         line-height: 1.45rem;
         letter-spacing: -.8px;
         word-spacing: 1px;
         padding: .3rem 0 0 1rem;
         grid-gap: 1.5rem;
      }
   }

   .nav-tabs .nav-item {
      margin-right: 4%;
   }

   .tab-content {
      ul {
         padding: 0 1rem;
      }
   }

   .duplet:not(.position) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 1.7rem;
   }

   .triplet .pnc-field-item {
      .wrapper {
         display: grid;
         grid-template-columns: 3fr 2fr;
      }

      .first {
         grid-column: 1/3;
      }

      .third {
         justify-self: end;
         align-self: self-start;
      }
   }

   .pnc-field-item:not(.affiliates-item) {
      margin: 0;
      padding: .9rem 0;

      .first {
         font-size: 1rem;
      }

      .second {
         font-size: 1.2rem;
         line-height: 1.4rem;
      }
   }

   .triplet .pnc-field-item.even {
      background-image: radial-gradient(circle 46vw, #e0e0e0, #edeeec);
   }

   .affiliates-name {
      font-size: 1.5rem;
      margin-bottom: 0;
   }

   .affiliates-wrapper {
      margin-top: -2px;

      ul.position {
         padding: 1.2rem 1.2rem 1.2rem .9rem;
      }

      .affiliates-item .yearpos-wrapper {
         .year {
            font-size: 1.1rem;
            line-height: 1.1rem;
         }

         .position {
            font-size: 1.4rem;
            line-height: 1.6rem;
         }
      }
   }
}


@include respond-to(s) {    // 666p
   .upper-content,
   .nav-tabs#courseTab {
      .content {
         padding-left: .9rem;
      }
   }

   .pnc-field-item {
      .second {
         font-size: 1.3rem;
      }

      .third {
         font-size: 1.1rem;
      }
   }
}


@include respond-to(m) {    // 777px
   .view-people-and-courses {
      .nav-tabs {
         font-size: 1.3rem;

         .nav-item a {
            padding: .6rem 1.1rem;
         }
      }

      .nav-tabs#personTab {
         justify-content: space-evenly;
      }
   }

   .upper-content,
   .coursegroup-wrapper {
      .label {
         font-size: 1.15rem;
         line-height: 1.3rem;
      }

      .content {
         font-size: 1.3rem;
      }
   }

   .pnc-field-item {
      .first {
         font-size: 1.15rem;
         line-height: 1.3rem;
      }

      .second {
         font-size: 1.5rem;
         line-height: 1.8rem;
      }

      .third {
         font-size: 1.15rem;
      }
   }
}


@include respond-to(l) {    // 888px
   #views-exposed-form-people-and-courses-search-page {
      .views-widget-filter-title {
         font-size: 1.35rem;
         line-height: 1.5rem;
      }
   }

   .view-people-and-courses {
      .nav-tabs {
         max-width: 782px;
         margin: 0 auto;
      }
   }

   .nav-tabs#courseTab {
      max-width: unset;
   }

   .tab-content {
      ul {
         grid-template-columns: 1fr 1fr 1fr;
      }
   }

   .triplet .pnc-field-item.even {
      background-image: radial-gradient(circle 40vw, #e0e0e0, #edeeec);
   }
}


@include respond-to(xl) {    // 999px
   .upper-content {
      padding: 0 7rem;
      margin-bottom: 2rem;
   }

   .nav-tabs#courseTab {
      padding: 0 8.7rem;
   }

   .affiliates {
      padding: 0 5.5rem;
      max-width: 910px;
      margin: 0 auto;
   }

   .triplet .pnc-field-item.even {
      background-image: radial-gradient(circle 37vw, #e0e0e0, #edeeec);
   }

   .tab-content {
      ul {
         padding: 0 7rem;
         margin: 1.5rem auto 0;
      }
   }
}

}

/** REMOVE AFTER DEBUGGING **/
// ul.tabs {
//    display: none;
// }


.page-about {
   .views-row {
      @include typeface(latoLight);
      font-size: 1rem;
      word-spacing: 1px;
      line-height: 1.5rem;
      letter-spacing: -.4px;
      font-weight: normal;
      color: color(blackgreenTwoThirds);
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
         color: color(blackgreen);
      }

      .about-image-a {
         padding: 0 8%;
         margin-bottom: 1.3rem;
      }

      .about-image-b {
         order: 1;
         padding: 0 8%;
      }

      .about-copy {
         margin-bottom: 1.3rem;
      }

      .about-copy-b {
         order: 2;
         margin-bottom: 0;
      }

      .about-leadership-wrapper {
         order: 3;
      }

      .about-leadership-person {
         display: flex;
         align-items: center;
         margin-bottom: 1rem;
         padding: 0 5%;

         .about-leadership-headshot {
            flex-basis: 25%;
         }

         .about-leadership-copy {
            flex-basis: 75%;
            padding-left: 2rem;

            .person {
               @include typeface(latoRegular);
            }
         }
      }

      .about-support {
         order: 4;

         .about-supporter-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 1rem 0;
         }

         .about-supporter {
            display: flex;
            margin: 0;
            padding: 0;
            justify-content: center;
         }

         .about-supporter.nsf img {
            max-height: 29vw;
         }

         .about-supporter.whoi-lib img,
         .about-supporter.mbl img {
            padding: 5%;
         }
      }
   }

@include respond-to(xxs) {
   .views-row {
      font-size: 1.2rem;
      word-spacing: 2px;
      line-height: 1.63rem;

      .about-support {
         order: 3;
         padding: 0;

         .about-support-lead-in {
            margin-bottom: 1rem;
         }

         .about-supporter-wrapper {
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem 2rem;
            align-items: center;
            margin-bottom: 2rem;
         }

         .about-supporter.nsf img {
            max-height: 21vw;
         }

         .about-supporter.whoi-lib {
            grid-column: 1/3;
         }
      }
   }
}

@include respond-to(xs) {
   .views-row {
      .about-copy,
      .about-leadership-wrapper,
      .about-support {
         padding: 0 5%;
      }

      .about-image-a,
      .about-image-b {
         padding: 0 5%;
      }
   }
}

@include respond-to(m) {
   .views-row {
      align-items: flex-start;
      flex-wrap: wrap;
      flex-direction: row;

      .about-image-a,
      .about-image-b {
         flex-basis: 40%;
      }

      .about-image-a {
         margin: .3rem 0 0;
         padding: 0 4% 0 2%;
      }

      .about-image-b {
         margin: 2.3rem 0 0;
         padding: 0 2% 0 4%;
         order: 1;
      }

      .about-copy {
         margin: 0;
         padding: 0;
      }

      .about-copy-a,
      .about-copy-b {
         flex-basis: 59%;
      }

      .about-copy-b {
         order: 0;
      }

      .about-leadership-wrapper {
         order: 2;
         padding: 0;
      }

      .about-support {
         padding: 0;
      }
   }
}

@include respond-to(l) {
   .views-row {
      padding: 3%;
      font-size: 1.35rem;
      line-height: 1.95rem;
      letter-spacing: -.7px;
      word-spacing: 3px;

      .about-leadership-wrapper {
         display: grid;
         grid-template-columns: 1fr 1fr;

         > p {
            grid-column: 1/3;
         }

         .about-leadership-person .about-leadership-copy {
            line-height: 1.7rem;
         }
      }
   }
}

}

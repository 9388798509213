footer {
   // background-image: url('/sites/default/files/sandy-seabed-100.png');
   background-color: color(papyrusSandish);
   background-size: cover;
   margin-top: auto;

   .footer-content-wrapper {
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: .5rem 1.3rem;
      padding: 3.7% 3.7% 2.8%;
      max-width: 1450px;
      margin: 0 auto;

      .copyright {
         @include typeface(openSans);
         font-size: .7rem;
         line-height: .9rem;
         color: color(blackgreenTwoThirds);
         grid-column: 1/3;
      }

      .mbl-whoi-logo {
         grid-column: 2/3;
      }

      .mbl-logo {
         grid-column: 1/2;
         max-height: 100px;
      }
   }

@include respond-to(m) {
   .footer-content-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 1.7% 2% 1.3%;
      grid-gap: 0;

      .copyright {
         grid-column: 3/4;
      }

      > div {
         padding: 0 4%;
      }
   }
}

}

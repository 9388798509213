// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';
@import 'misc/font-min-max';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.header,
%header {
  @extend %clearfix;

   &-wrapper {
      background-color: color(sea);
      background-image: url('/sites/all/themes/contrib/hmbl_zen/images/MBL_textured_bg.jpg');
      // height: 9.25rem;
      position: relative;
      height: 148px;

      > .layout-center {
         height: calc(100% - 34px);
      }
   }

   &__title {
      text-align: center;
      // padding: 0 5% 2%;
      padding: 1% 5%;
      letter-spacing: -.0175rem;
   }

   // We don't want the title using the default <a> colors or underline
   &__title a {
      font-size: 39px;
      color: color(papyrus);
      text-decoration: unset;
   }

   &__title-prefix {
      @include typeface(titlePrefix);
      font-size: 55%;
      line-height: 1.15em;
      padding-top: .32em;
   }

   &__title-main {
      @include typeface(titleMain);
      line-height: .85em;
   }

   &__site-slogan {
      @include typeface(siteSlogan);
      @include responsive-font(1.35vw, 13.3px, 16px);
      color: color(papyrusSand);
      line-height: 1.2em;
      text-align: center;
   }

   // @include zen-respond-to(xxxs) {
   //    // @include responsive-font(20vw, 35px, 44px);
   //    // font-size: calc(4.955vw + 7.5px);
   // }

   // @media (min-width: 414px) {
   //    &__title a {
   //       font-size: calc(4.955vw + 7.5px);
   //    }

   //    &__site-slogan {
   //       line-height: 1.3em;
   //    }
   // }

   // @include zen-respond-to(xs) {
   //    &__title a {
   //       @include responsive-font(5vw, 35px, 44px);
   //       text-align: center;
   //    }

   //    &__title-main {
   //       line-height: .87em;
   //    }

   //    &__site-slogan {
   //       text-align: center;
   //    }
   // }

   @include zen-respond-to(xxs) {      //    444px
      &-wrapper {
         // height: calc(3.91vw + 158px);
         height: 180px;

         > .layout-center {
            height: calc(100% - 36px);
         }
      }

      &__title {
         padding: 0;

         a {
            font-size: calc(2.41vw + 38.3px);
         }
      }

      &__title-prefix {
         padding: 0;
      }
   }

   @include zen-respond-to(xs) {    //    555px
      &-wrapper {
         height: 190px;

         > .layout-center {
            height: calc(100% - 40px);
         }
      }
   }

   @include zen-respond-to(m) {
      &-wrapper {
         > .layout-center {
            height: calc(100% - 36px);
            padding: 0;
         }
      }

      &__title {
         padding: 0 5%;

         a {
            font-size: calc(1.49vw + 35.5px);
         }
      }

      &__site-slogan {
         font-size: calc(.59vw + 11.38px);
      }
   }

   @include zen-respond-to(l) {    //    888px
      &-wrapper {
         height: 200px;
      }
   }

   @include zen-respond-to(xl) {
      &__title {
         text-align: center;
      }

      &__site-slogan {
         padding: 0 8%;
      }
   }

   @include zen-respond-to(xxl) {
      &-wrapper {
         height: 218px;
      }

      &__site-slogan {
         padding: 0 11%;
      }
   }

   @include zen-respond-to(max-centered) {
      &__title a {
         font-size: 57px;
      }
   }


  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    // Clear the logo.
    clear: both;
  }
}

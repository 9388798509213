// This DOM element isn't a child of .layout-browse, but it is
//  associated with items therein, so we're putting it here 
#lightbox {
   // sass-lint:disable id-name-format

   #lightboxImageWrapper {
      line-height: 0;
   }

   #lightboxDetails {
      padding: 0;
   }

   // sass-lint:enable id-name-format
}

.page-taxonomy-term,
%page-taxonomy-term {

   .views-row {
      margin-bottom: 4rem;
      display: flex;
      align-items: flex-start;

      .taxonomy-item-thumb {
         flex-basis: 42%;
         line-height: 0;

         .field-content {
            box-shadow: 0 0 4px #676767;
         }
      }

      .taxonomy-item-copy {
         padding-left: 6%;
         flex-basis: 58%;

         .taxonomy-item-title {
            @include typeface(latoBoldItalic);
            font-size: 1.1rem;
            line-height: 1.2rem;
            letter-spacing: .2px;
            margin-bottom: .7rem;

            a {
               color: color(blackgreen);
               text-decoration: none;
            }

            a:hover {
               text-decoration: underline;
            }
         }

         .taxonomy-item-date,
         .taxonomy-item-desc,
         .taxonomy-item-tags {
            @include typeface(openSansLight);
            font-size: .85rem;
            line-height: 1.1rem;
            letter-spacing: -.2px;
            color: color(blackgreen);
         }

         .taxonomy-item-desc {
            margin-bottom: .9rem;

            // Clamp the description at 3 lines
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 3;

            p {
               margin: 0;
            }
         }

         // The below is two bits of kludge for the price of one.
         //  In order to keep autoprefixer from removing the '-webkit-box-orient'
         //  directive we have to disable autoprefixer. But we can only do that
         //  for the entire block, so we move it into its own rule. If we use
         //  the same selector as above, the linter will complain that the two
         //  rules should be combined, thus, the addition of a second class to the selector
         .field-content .taxonomy-item-desc {
            /*! autoprefixer: off */
            -webkit-box-orient: vertical;
         }

         .taxonomy-item-date,
         .taxonomy-item-tags {
            color: color(blackgreenTwoThirds);

            .field-content {
               text-transform: capitalize;
               font-style: italic;

               a {
                  color: color(blackgreen);
               }
            }

         }
      }

      .youtube-item-copy {
         .taxonomy-item-title,
         .taxonomy-item-desc {
            margin-bottom: .3rem !important;
         }
      }
   }

   @include respond-to(xxs) {
      .views-row .taxonomy-item-copy .taxonomy-item-title {
         font-size: 1.2rem;
         line-height: 1.4rem;
      }
   }

   @include respond-to(xs) {

      .view-content {
         display: flex;
         flex-wrap: wrap;
         justify-content: flex-start;
         align-items: baseline;
         padding: 0;
      }

      .views-row {
         flex-direction: column;
         flex-basis: 50%;
         margin-bottom: 5rem;
         align-items: center;

         .taxonomy-item-thumb {
            flex-basis: unset;
            margin: 0 11%;
            position: relative;
         }

         .taxonomy-item-copy {
            flex-basis: unset;
            margin: 1rem 11% 0;
            padding: 0 1%;
            align-self: unset;

            .taxonomy-item-title {
               font-size: 1.25rem;
               line-height: 1.4rem;
               margin-bottom: .8rem;
            }

            .taxonomy-item-date,
            .taxonomy-item-desc,
            .taxonomy-item-tags {
               font-size: .9rem;
               line-height: 1.2rem;
               letter-spacing: -.4px;
            }

            .taxonomy-item-desc {
               -webkit-line-clamp: 4;
               margin-bottom: 1rem;
            }
         }

         .youtube-video-content {
            width: 100%;
            margin: 0 !important;
            padding: 0 30px !important;
         }

         .youtube-item-copy {
            width: 100%;
            margin: 1rem 0 0;
            padding: 0 30px !important;
         }
      }
   }

   @include respond-to(m) {
      .views-row {
         flex-basis: 33.3333333%;
      }
   }

   @include respond-to(l) {
      .views-row {
         .taxonomy-item-copy {
            .taxonomy-item-title {
               font-size: 1.35rem;
               line-height: 1.5rem;
            }

            .taxonomy-item-date,
            .taxonomy-item-desc,
            .taxonomy-item-tags {
               font-size: 1rem;
               line-height: 1.3rem;
            }
         }
      }
   }
}

// .page-taxonomy-term.views-browse-exhibits .layout-browse {
//    padding: 0 3%;

//    .taxonomy-item-copy {
//       align-self: center;

//       .taxonomy-item-title {
//          font-size: 1.8rem;
//          line-height: 2rem;
//       }
//    }
// }

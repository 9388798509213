// Font faces
//
// Instead of relying on the fonts that are available on a user's computer, you
// can use web fonts which, like images, are resources downloaded to the user's
// browser. Because of the bandwidth and rendering resources required, web fonts
// should be used with care.
//
// Numerous resources for web fonts can be found on Google. Here are a few
// websites where you can find Open Source fonts to download:
// - http://www.fontsquirrel.com/fontface
// - http://www.theleagueofmoveabletype.com
//
// In order to use these fonts, you will need to convert them into formats
// suitable for web fonts. We recommend the free-to-use Font Squirrel's
// Font-Face Generator:
//   http://www.fontsquirrel.com/fontface/generator
//
// The following is an example @font-face declaration. This font can then be
// used in any ruleset using a property like this:  font-family: Example, serif;
//
// Since we're using Sass, you'll need to declare your font faces here, then you
// can add them to the font variables in the _init.scss partial.

@font-face {
   font-family: 'GB1911';
   src: url('../fonts/Goudy_Bookletter_1911/GoudyBookletter1911-Regular.ttf');
}

@font-face {
   font-family: 'OpenSansLight';
   src: url('../fonts/Open_Sans/OpenSans-Light.ttf');
}

@font-face {
   font-family: 'OpenSansLightItalic';
   src: url('../fonts/Open_Sans/OpenSans-LightItalic.ttf');
}

@font-face {
   font-family: 'OpenSans';
   src: url('../fonts/Open_Sans/OpenSans-Regular.ttf');
}

@font-face {
   font-family: 'OpenSansItalic';
   src: url('../fonts/Open_Sans/OpenSans-Italic.ttf');
}

@font-face {
   font-family: 'OpenSansSemiBold';
   src: url('../fonts/Open_Sans/OpenSans-SemiBold.ttf');
}

@font-face {
   font-family: 'FontAwesome';
   src: url('../fonts/Font_Awesome/fa-solid-900.ttf');
}

@font-face {
   font-family: 'LatoLight';
   src: url('../fonts/Lato/Lato-Light.ttf');
}

@font-face {
   font-family: 'LatoRegular';
   src: url('../fonts/Lato/Lato-Regular.ttf');
}

@font-face {
   font-family: 'LatoItalic';
   src: url('../fonts/Lato/Lato-Italic.ttf');
}

@font-face {
   font-family: 'LatoBold';
   src: url('../fonts/Lato/Lato-Bold.ttf');
}

@font-face {
   font-family: 'LatoBoldItalic';
   src: url('../fonts/Lato/Lato-BoldItalic.ttf');
}

@font-face {
   font-family: 'LatoBlack';
   src: url('../fonts/Lato/Lato-Black.ttf');
}

// @font-face {
//   font-family: 'Example';
//   src: url('../fonts/example.eot');
//   src: url('../fonts/example.eot?iefix') format('eot'),
//     url('../fonts/example.woff') format('woff'),
//     url('../fonts/example.ttf') format('truetype'),
//     url('../fonts/example.svg#webfontOkOndcij') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

.action-links {
   display: none;
}

main {
   max-width: 1140px;
   margin: 0 auto;

   > h1 {
      @include typeface(openSansLight);
      text-transform: capitalize;
      line-height: 2.3rem;
      margin-top: 1rem;
      color: rgba(6, 47, 50, .66);
      letter-spacing: -.6px;
      font-weight: 100;
   }
}

.page-search main > h1 {
   margin-top: 1.7rem;
   margin-bottom: 1.2rem;
   letter-spacing: -.5px;
}

.page-about main > h1 {
   text-transform: none;
}

.front main {
   max-width: 100%;
}

.section-people-and-courses main {
   max-width: 1000px;
}

.section-people-and-courses.node-type-pnc-institution main > h1 {
   margin-bottom: 2rem;
}


@include respond-to(xxs) {        // 444px
   .section-people-and-courses main {
      > h1 {
         font-size: 2.2rem;
         margin: 1.2rem 0 1.7rem;
      }
   }
}

@include respond-to(xs) {        // 555px
   main {
      > h1 {
         @include typeface(openSans);
         font-size: 2.4rem;
         letter-spacing: unset;
         margin-top: 1.1rem;
         margin-bottom: 1.9rem;
      }
   }

   .page-search main {
      > h1 {
         margin-bottom: 1.4rem;
      }
   }

   .section-people-and-courses {
      main {
         > h1 {
            letter-spacing: -1.4px;
            font-size: 2.4rem;
            padding: 0 1rem;
         }
      }
   }

   .section-people-and-courses.node-type-pnc-institution {
      main {
         > h1 {
            margin: 1.7rem 0 2.7rem;
         }
      }
   }
}


@include respond-to(s) {        // 666px
   .section-people-and-courses {
      > .layout-center {
         padding: 0 2.4em;
      }
   }
}


@include respond-to(m) {        // 777px
   .section-people-and-courses {
      > .layout-center {
         padding: 0 3.3em;
      }
   }

   main {
      > h1 {
         font-size: 2.2rem;
         padding: 0 12px;
      }
   }
}

@include respond-to(l) {      // 888px
   main {
      > h1 {
         font-size: 2.7rem;
         margin-top: 1.5rem;
         margin-left: 2%;
         letter-spacing: -2.2px;
         line-height: 3.1rem;
      }
   }

   .page-search main {
      > h1 {
         margin-top: 2.4rem;
         margin-bottom: 2.4rem;
      }
   }
}

@include respond-to(xl) {     // 999px
   .section-people-and-courses {
      main > h1 {
         font-size: 2.6rem;
         padding: 0 4rem;
      }
   }
}

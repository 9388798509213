.node-type-exhibit-page #lightbox {
   max-width: 96vw;
}

@media (max-width: 600px) {
   .node-type-exhibit-page #lightbox {
      left: 2vw !important;
   }
}

.node-type-exhibit-page main {

   > h1 {
      letter-spacing: -1.66px;
   }

   .field-name-field-exhibit-layout {
      width: 100%;
   }

   .exhibit-content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .poster-and-menu {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: center;

         .exhibit-poster {
            display: inline-block;
            line-height: 0;
            margin-bottom: 1.35rem;

            .field-content {
               max-width: 300px;
            }
         }

         .menu-block-3 {
            width: 100%;
            margin-bottom: 1rem;

            // Indent first level of Exhibit menu
            > ul > li {
               a {
                  padding-left: 1rem;
               }

               // Indent second level of Exhibit menu
               > ul > li {
                  a {
                     padding-left: 2rem;
                  }

                  // Indent third level of Exhibit menu
                  > ul > li {
                     a {
                        padding-left: 3rem;
                     }
                  }
               }
            }

            a {
               display: inline-block;
               padding-top: .4rem;
               padding-right: .7rem;
               padding-bottom: .35rem;
            }

            ul {
               margin-top: 0;
               padding-left: 0;
            }

            > ul.menu {
               > li:not(.is-active-trail) {
                  display: none;
               }
            }

            li {
               @include typeface(openSansLight);
               letter-spacing: -.3px;
               font-size: .95rem;
               line-height: 1.2rem;
               list-style: none;
               background-color: color(seaweedDarker);

               a {
                  width: 100%;
                  border-bottom: 1px solid #9ea99e;
                  text-decoration: none;
               }

               a:hover {
                  text-decoration: underline;
               }

               > a {
                  color: color(papyrusLighter);
               }
            }

            @include respond-to(s) {
               // li {
               //    font-size: .825rem;
               //    letter-spacing: -.2px;
               // }

               a {
                  padding-top: .5rem;
                  padding-bottom: .45rem;
               }
            }

            // @include respond-to(m) {
            //    li {
            //       font-size: .95rem;
            //       letter-spacing: -.4px;
            //    }

            //    a {
            //       padding-top: .6rem;
            //       padding-bottom: .6rem;
            //    }
            // }

            li.is-active {
               @include typeface(openSansSemiBold);
               background-color: color(papyrusSandish);

               > a {
                  color: color(seaweed);
                  text-decoration: underline;
                  position: relative;
               }

               // Put an indicator next to the current page in the Exhibit menu
               // > a::before {
               //    font-family: FontAwesome;
               //    content: '';
               //    position: absolute;
               //    left: 3px;
               //    top: 50%;
               //    transform: translateY(-50%);
               // }
            }
         }
      }

      .body-endmatter {
         @include typeface(openSansLight);
         color: color(blackgreen);
         font-size: .92rem;
         // margin-bottom: 1.4rem;
         line-height: 1.35rem;

         .body {
            margin-bottom: 1.3rem;

            p {
               margin: 0 0 .9rem;
            }
         }
      }

      .citation,
      .bibliography {
         // font-size: .9rem;
         // line-height: 1.15rem;
         letter-spacing: -.35px;
         color: color(seaweed);
         word-break: break-all;  // allows browser to break long url
         align-self: flex-start;
         margin-bottom: 1.3rem;

         .label {
            @include typeface(openSansLightItalic);
            font-size: 1.1rem;
            line-height: 1.1rem;
            letter-spacing: -.4px;
            color: color(blackgreenTwoThirds);
            font-weight: normal;
            text-decoration: underline;
            cursor: pointer;
            margin-bottom: .1rem;

            .caret {
               @include typeface(fontAwesome);
               position: absolute;
               left: -10px;
               transform: rotate(0deg);
               transform-origin: left 50%;
               transition: transform 300ms;
               display: none;
            }

            .caret.open {
               transform: rotate(90deg);
               transform-origin: left 50%;
               transition: transform 300ms;
            }
         }

         .content {
            @include typeface(latoLight);
            padding-left: 1.3rem;
            // display: none;
            margin-top: .7rem;

            p {
               margin: 0 0 .3rem;
            }
         }

         #exhibit-citation,
         #exhibit-bibliography {
            display: none;
         }

         #exhibit-bibliography ul {
            padding-left: 1.1rem;
            margin-top: 0;
         }
      }

      .sidebar-right {
         .item-list > ul {
            padding: 0;
            margin: 0 0 2.8rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: baseline;
            list-style-type: none;

            li {
               margin-bottom: 2rem;
               line-height: 0;
            }

            .content {
               display: flex;
               flex-direction: column;

               > * {
                  flex-basis: unset;
                  margin: 0 11% .4rem;
                  position: relative;
                  font-family: latoBoldItalic;
                  font-size: 1.1rem;
                  line-height: 1.2rem;
                  text-align: center;
                  color: color(seaweedDarker);

                  a {
                     color: color(seaweedDarker);
                  }
               }

               p {
                  margin: 0;
                  // margin-left: unset;
                  // margin-right: unset;
               }
            }
         }

         .exhibit-poster {
            display: none;
         }
      }

   }

@include respond-to(xxs) {       // 444px
   .exhibit-content-wrapper {
      .body-endmatter,
      .sidebar-right {
         font-size: 1.1rem;
         line-height: 1.6rem;
         letter-spacing: -.3px;

         // .related-items-all > .view-content {
         //    flex-direction: row;

         //    .related-item-single {
         //       flex-basis: 50%;
         //    }
         // }

         .item-list > ul {
            flex-direction: row;

            li {
               flex-basis: 50%;
            }
         }
      }
   }
}

@include respond-to(s) {           // 666px
   .exhibit-content-wrapper {
      display: grid;
      grid-template-columns: 44fr 56fr;
      column-gap: 1.375rem;
      align-items: start;

      .poster-and-menu {
         width: 100%;
         grid-column: 1 / 2;
         margin-top: .2rem;

         .menu-block-3 {
            width: 100%;
            margin-bottom: 3rem;
         }

         .field-name-field-poster-image {
            padding: 0;
            margin-bottom: 1.75rem;
         }
      }

      .body-endmatter {
         width: 100%;
         grid-column: 2 / 3;
      }

      .sidebar-right {
         grid-column: 1 / 3;
      }

      .body-endmatter,
      .sidebar-right {
         padding: 0;
         line-height: 1.65rem;
         word-spacing: 1px;
         letter-spacing: -.8px;
      }
   }
}

@include respond-to(l) {           // 888px
   .exhibit-content-wrapper {
      grid-template-columns: 32fr 45fr 23fr;
      column-gap: 1.3rem;

      .poster-and-menu {
         span {
            width: 100%;
         }

         .exhibit-poster {
            display: none;
         }
      }

      .sidebar-right {
         grid-column: 3 / 4;

         // .related-items-all > .view-content {
         //    flex-direction: column;

         //    .related-item-single {
         //       flex-basis: unset;
         //    }
         // }

         .item-list > ul {
            flex-direction: column;

            li {
               flex-basis: unset;
            }
         }

         .exhibit-poster {
            display: inline-block;
         }
      }
   }
}

@include respond-to(xl) {           // 999px
   > h1 {
      margin: 1.8rem 0;
   }

   .exhibit-content-wrapper {
      column-gap: 1.7rem;

      .poster-and-menu .menu-block-3 li {
         font-size: 1rem;
         line-height: 1.2rem;
      }
   }
}

}
